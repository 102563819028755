import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { Navbar } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container } from 'react-bootstrap'
import SEO from './seo'
import { StaticImage } from 'gatsby-plugin-image'

// Defines the header/navbar of every page on the website.
// TODO: Prevent the need for using page title as a prop, use only site name on home page.
// TODO: Styling. Conde Nast-like font?
// TODO: Extract styles and strings into a central file.
// TODO: Change Navbar.Toggle icon to match text colour.
const Header = ({pageTitle, pageDescription = null}) => {
    const data = useStaticQuery(graphql`
    query {
        site {
        siteMetadata {
            title
        }
        }
    }
    `)
    return (
        <div style={{background:"#373f47"}}>
            <SEO title={pageTitle} description={pageDescription}/>
            <Container fluid="xxl">
                <Navbar collapseOnSelect expand="md" variant="dark" style={{marginTop:"8px", marginBottom:"8px"}}>
                    <Navbar.Brand href="https://tnnl.co.uk" style={{color:"#f5efed", fontSize:32}}>
                        <StaticImage src="../images/Brand Logo.png" height="60" alt="TNNL"/>    
                    </Navbar.Brand>
                </Navbar>
            </Container>
        </div>
    )
}

export default Header